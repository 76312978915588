import React from "react";
import styled from "styled-components";
import MetricSnippet from "../MetricSnippet";
import Container from "../styles/Container";
import { brandColor2 } from "../styles/Colors";
import { mediaMedium } from "../styles/variables";

const Wrapper = styled.div`
  background-color: ${brandColor2};
  padding: 88px 0;

  @media (${mediaMedium}) {
    padding: 145px 0 147px;
  }
`;

const Grid = styled(Container)`
  display: grid;
  grid-template-columns: 100%;
  grid-row-gap: 56px;

  @media (${mediaMedium}) {
    grid-template-columns: repeat(3, 250px);
    justify-content: center;
    grid-gap: 32px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StudioStatistics = () => {
  return (
    <Wrapper>
      <Grid>
        <Column>
          <MetricSnippet
            suffix="K+"
            number="40"
            description="Articles Created <br/> All Time"
            align={true}
          />
        </Column>
        <Column>
          <MetricSnippet
            suffix="+"
            number="30"
            description="Writers in  <br/> Our Network"
            align={true}
          />
        </Column>
        <Column>
          <MetricSnippet
            suffix="+"
            number="50"
            description="Publishers Working with  <br/> Content Studio"
            align={true}
          />
        </Column>
      </Grid>
    </Wrapper>
  );
};

export default StudioStatistics;

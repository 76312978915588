import React from "react";
import StudioStatistics from "../publishers/StudioStatistics";
import WritersFrom from "../publishers/WritersFrom";
import StudioTestimonials from "../publishers/StudioTestimonials";
import HeroBlueCTA from "../HeroBlueCTA";
import { Link } from "gatsby";

const PublishersContentStudioDefer = () => {
  return (
    <>
      <StudioStatistics />

      <WritersFrom />

      <StudioTestimonials />

      <HeroBlueCTA
        title="Let’s work"
        outline="Together"
        description="See how Stack can help you grow your commerce and ad revenues."
        linkLabel="Get Started"
        link={{ to: "/contact/?smopt=publisher", state: { from: "publisher" }, as: Link }}
      />
    </>
  );
};

export default PublishersContentStudioDefer;

import React from "react";
import HeroTestimonials from "../HeroTestimonials";

import imageOne from "../../images/26-testimonial-baybars-umur.png";
import imageTwo from "../../images/26-testimonial-justin-wohl.png";
import imageThree from "../../images/26-testimonial-avery-driggers.png";

const data = [
  {
    image: imageOne,
    description:
      "Baybars Umur, Head of Sales + Marketing @ Interesting Engineering",
    content:
      "“Stack’s Content Studio delivers consistently high-quality content that both fits our editorial voice and drives meaningful revenue.”"
  },
  {
    image: imageTwo,
    description: "Justin Wohl, Director of Operations @ Salon.com",
    content:
      "“StackCommerce’s Content Studio consistently delivers quality, well-written content that speaks directly to our readers. Their full-service content solution lessens the time and manpower typically required by a commerce program, and allows us to focus our team’s efforts elsewhere.”"
  },
  {
    image: imageThree,
    description: "Avery Driggers, Content Strategist and Commerce Lead @ Digg",
    content:
      "“StackCommerce provides us with a seamless and scalable solution for producing commerce-focused editorial pieces with its Content Studio. We’re happy to see Digg’s audience responding positively to the content.”"
  }
];

const StudioTestimonials = () => {
  return <HeroTestimonials data={data} />;
};

export default StudioTestimonials;

import React from "react";
import styled from "styled-components";
import { mediaMedium } from "./styles/variables";

const Grid = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  text-align: center;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 58px;
  grid-column-gap: 64px;

  .gatsby-image-wrapper {
    margin: 0 auto;
    overflow: visible !important;
  }

  @media (max-width: 1022px) {
    div:nth-child(3n) {
      .gatsby-image-wrapper {
        margin-right: 0;
      }
    }

    div:nth-child(3n-2) {
      .gatsby-image-wrapper {
        margin-left: 0;
      }
    }
  }

  @media (${mediaMedium}) {
    grid-template-columns: repeat(6, 1fr);
    grid-row-gap: 74px;
    grid-column-gap: 24px;

    div:nth-child(7n) {
      .gatsby-image-wrapper {
        margin-left: 0;
      }
    }

    div:nth-child(6n) {
      .gatsby-image-wrapper {
        margin-right: 0;
      }
    }
  }
`;

const BrandsGrid = ({ children }) => {
  return <Grid>{children}</Grid>;
};

export default BrandsGrid;

import React from "react";
import { useInView } from "react-intersection-observer";
import { MoveInBottom } from "./styles/animations";

const MoveInDiv = ({
  children,
  triggerOnce = true,
  translateFrom,
  threshold = [0.3, 0.5, 0.75]
}) => {
  const { ref, inView } = useInView({
    threshold: threshold,
    delay: 30,
    triggerOnce
  });

  return (
    <MoveInBottom translateFrom={translateFrom} ref={ref} active={inView}>
      {children}
    </MoveInBottom>
  );
};

export default MoveInDiv;

import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import Image from "../Image";
import BrandsGrid from "../BrandsGrid";
import Container from "../styles/Container";
import { Headline64 } from "../styles/Headings";
import { black1, white } from "../styles/Colors";
import { mediaMedium } from "../styles/variables";
import MoveInDiv from "../MoveInDiv";

// Images

const Background = styled.div`
  background-color: ${black1};
  padding: 92px 0 98px;

  @media (${mediaMedium}) {
    padding: 152px 0 161px;
  }
`;

const TitleWrapper = styled.div`
  padding-bottom: 56px;

  @media (${mediaMedium}) {
    text-align: center;
    padding-bottom: 93px;
  }
`;

const WritersFromSection = ({ data }) => {
  return (
    <Background>
      <Container>
        <TitleWrapper>
          <Headline64 theme={{ color: white }}>
            We Hire Experienced Writers From Top Publications
          </Headline64>
        </TitleWrapper>
        <BrandsGrid>
          {data &&
            Object.keys(data).map((item) => (
              <div key={item}>
                <MoveInDiv threshold={[0.1, 0.2, 0.5, 0.75]}>
                  <Image fluid={data[item].childImageSharp.fluid} />
                </MoveInDiv>
              </div>
            ))}
        </BrandsGrid>
      </Container>
    </Background>
  );
};

const WritersFrom = () => {
  const data = useStaticQuery(graphql`
    query {
      gizmodo: file(relativePath: { eq: "25-gizmodo.png" }) {
        childImageSharp {
          fluid(maxWidth: 151, maxHeight: 22, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      cnn: file(relativePath: { eq: "25-cnn.png" }) {
        childImageSharp {
          fluid(maxWidth: 87, maxHeight: 44, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      forbes: file(relativePath: { eq: "25-forbes.png" }) {
        childImageSharp {
          fluid(maxWidth: 119, maxHeight: 30, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      yahoo: file(relativePath: { eq: "25-yahoo.png" }) {
        childImageSharp {
          fluid(maxWidth: 129, maxHeight: 35, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      cnet: file(relativePath: { eq: "25-cnet.png" }) {
        childImageSharp {
          fluid(maxWidth: 65, maxHeight: 65, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      redbook: file(relativePath: { eq: "25-redbook.png" }) {
        childImageSharp {
          fluid(maxWidth: 121, maxHeight: 25, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      cnbc: file(relativePath: { eq: "25-cnbc.png" }) {
        childImageSharp {
          fluid(maxWidth: 84, maxHeight: 65, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      goodHousekeeping: file(relativePath: { eq: "25-good-housekeeping.png" }) {
        childImageSharp {
          fluid(maxWidth: 142, maxHeight: 30, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      comospolitan: file(relativePath: { eq: "25-comospolitan.png" }) {
        childImageSharp {
          fluid(maxWidth: 135, maxHeight: 25, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      travelLeisure: file(relativePath: { eq: "25-travel-leisure.png" }) {
        childImageSharp {
          fluid(maxWidth: 128, maxHeight: 39, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      popularScience: file(relativePath: { eq: "25-popular-science.png" }) {
        childImageSharp {
          fluid(maxWidth: 111, maxHeight: 40, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      ad: file(relativePath: { eq: "25-ad.png" }) {
        childImageSharp {
          fluid(maxWidth: 87, maxHeight: 65, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);
  return <WritersFromSection data={data} />;
};

export default WritersFrom;
